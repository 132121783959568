<template>
  <!-- 支付订单记录 -->
  <div class="payment-record-query">
    <Scroll
      class="region-scroll"
      ref="scroll"
      @pullingUp="scrollBottom"
      :pullUpLoad="true"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        @load="onLoad"
        error-text="请求失败，点击重新加载"
        finished-text="没有更多了"
      >
        <!-- 空状态 -->
        <div v-if="paymentOrderList.list.length == 0">
          <van-empty description="无支付记录" />
        </div>
        <!-- 挂号信息 -->
        <div
          class="payment-record-query-list"
          v-for="item in paymentOrderList.list"
          :key="item.createTime"
        >
          <div>
            <span>支付编号:</span>
            <span>{{item.bid}}</span>
          </div>
          <div>
            <span>类型:</span>
            <span>{{item.businessType}}</span>
          </div>
          <div>
            <span>支付状态:</span>
            <span>{{paystate[item.stat]}}</span>
          </div>
          <div>
            <span>支付方式:</span>
            <span>{{item.channel}}</span>
          </div>
          <div>
            <span>支付时间:</span>
            <span>{{item.createTime}}</span>
          </div>
          <div>
            <span>支付金额:</span>
            <span>{{item.selfMoney + '元'}}</span>
          </div>
        </div>
      </van-list>
    </Scroll>
  </div>
</template>

<script>
import Scroll from '@/components/content/scroll/BScroll'
import { payHistoryQuery } from '@/network/service'
import { times } from '@/common/utils'
export default {
  name: 'paymentRecordQuery',
  components: {
    Scroll
  },
  data () {
    return {
      //支付订单信息
      paymentOrderList: {
        page: 1,
        list: []
      },
      //支付状态
      paystate: {
        'TRADE_WAIT': '等待支付',
        'PAYING': '用户正在付款',
        'USER_SCANED': '用户已扫码',
        'CREATE_FAIL': '订单创建失败',
        'TRADE_CLOSED': '已关闭',
        'TRADE_CANCEL': '已撤销',
        'PAY_FAIL': '支付失败',
        'PAY_SUCCESS': '支付成功',
        'PARTIAL_REFUND': '部分退款',
        'FULL_REFUND': '全额退款',
        'REFUND_SUCCESS': '退款成功'
      },
      //阀门
      valve: true,
      //等待加载
      loading: false,
      //没有更多了
      finished: false,
      //请求失败，点击重新加载
      error: false,
    }
  },
  mounted () {
    //支付订单查询
    this.payHistoryQueryPost()
  },
  methods: {
    //点击重新加载
    onLoad () {
      this.payHistoryQueryPost()
    },
    //支付订单查询
    async payHistoryQueryPost () {
      //重新计算高度
      this.$nextTick(() => {
        this.$refs.scroll.refresh();
      });
      let res = await payHistoryQuery({
        open_id: this.$store.state.cardList[0].openId,
        begin_date: '1970-01-01',
        end_date: times(),
        page_no: this.paymentOrderList.page,
        page_size: this.$store.state.pageSet
      })
      if (res == undefined) {
        this.loading = false;
        this.error = true;
      } else if (res.code == "0") {
        //第一次加载没数据
        if (this.paymentOrderList.page == 1 && res.data.content.length == 0) {
          this.loading = false;
          this.finished = true;
          return
          //第一次加载数少于10个
        } else if (
          this.paymentOrderList.page == 1 &&
          res.data.content.length < this.$store.state.pageSet
        ) {
          this.paymentOrderList.list = [...res.data.content];
          this.loading = false;
          this.finished = true;
          this.$toast.clear()
        } else {
          this.loading = true;
          //添加信息
          this.paymentOrderList.list.push(...res.data.content);
          //上拉加载一页
          this.paymentOrderList.page += 1;
          //上拉加载更多
          this.$refs.scroll.finishPullUp();
          this.loading = false;
          this.$toast.clear()
          if (res.data.content.length == 0) {
            this.valve = false;
            this.finished = true;
          }
        }
        //重新计算高度
        this.$nextTick(() => {
          this.$refs.scroll.refresh();
        })
      }
    },
    //上拉加载更多
    scrollBottom () {
      if (this.valve && this.paymentOrderList.list.length >= this.$store.state.pageSet) this.payHistoryQueryPost()
    }
  }
}
</script>

<style lang="less" scoped>
.payment-record-query {
  background-color: #f4f4f4;
  height: 100%;
  .region-scroll {
    height: 100vh;
    overflow: hidden;
    .payment-record-query-list {
      position: relative;
      background-color: rgba(254, 254, 254);
      line-height: 2rem;
      padding: 0.5rem 0;
      margin-top: 0.5rem;
      div {
        display: flex;
        margin-left: 1rem;
        span:first-child {
          flex: 0.3;
          color: #8d8fa1;
        }
        span {
          flex: 0.6;
        }
      }
    }
  }
}
</style>